<template>
  <div class="table-container">
    <b-table
      :data="items"
      :loading="loading"
      striped
      hoverable
      bordered
      paginated
      backend-pagination
      backend-filtering
      backend-sorting
      detailed
      detail-key="id"
      pagination-size="is-small"
      :total="total"
      :per-page="perPage"
      @page-change="onPageChange"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
      :default-sort-direction="defaultSortOrder"
      :default-sort="[sortField, sortOrder]"
      @sort="onSort"
      @filters-change="filtersChange"
    >
      <template slot-scope="props">
        <b-table-column field="index" label="Index">{{
          sortOrder === 'asc' ? props.index + offset + 1 : total - props.index - offset
        }}</b-table-column>

        <b-table-column field="id" label="Serial Number" sortable searchable>{{ props.row.id }}</b-table-column>
        <b-table-column field="batch" label="Batch" searchable>{{ props.row.batch }}</b-table-column>
        <b-table-column field="product_name" label="Product name" sortable searchable>{{
          props.row.product_name
        }}</b-table-column>
        <b-table-column field="hardware_revision" label="Hardware revision">{{
          props.row.hardware_revision
        }}</b-table-column>
        <b-table-column field="assembly_variant" label="Assembly variant">{{
          props.row.assembly_variant
        }}</b-table-column>
        <b-table-column field="status" label="Status" sortable searchable>
          <template slot="searchable" slot-scope="props">
            <b-select placeholder="Select ..." v-model="props.filters[props.column.field]" size="is-small" expanded>
              <option v-for="option of statusOprions" :value="option" :key="option">
                {{ option }}
              </option>
            </b-select>
          </template>
          {{ props.row.status }}
        </b-table-column>
        <b-table-column field="imsi" label="IMSI" sortable searchable>{{ props.row.imsi }}</b-table-column>
        <b-table-column field="imei" label="IMEI" sortable searchable>{{ props.row.imei }}</b-table-column>
        <b-table-column field="bdaddr" label="BDADDR" sortable searchable>{{ props.row.bdaddr }}</b-table-column>
        <b-table-column field="sfid" label="SF" sortable searchable>{{ props.row.sfid }}</b-table-column>
        <b-table-column field="note" label="Note" searchable>
          {{ props.row.note && (props.row.note.length > 10 ? props.row.note.substr(0, 10) + '...' : props.row.note) }}
        </b-table-column>
        <b-table-column field="created_at" label="Created at" sortable>{{
          props.row.created_at | moment('D.M.YYYY H:mm:ss')
        }}</b-table-column>
      </template>

      <template slot="detail" slot-scope="props">
        <b-table-simple hover small caption-top responsive>
          <tr v-if="props.row.ble_passkey">
            <td>BLE passkey:</td>
            <td>{{ props.row.ble_passkey }}</td>
          </tr>
          <tr v-if="props.row.claim_token">
            <td>Claim token:</td>
            <td>{{ props.row.claim_token }}</td>
          </tr>
          <tr v-if="props.row.note">
            <td>Note:</td>
            <td>{{ props.row.note }}</td>
          </tr>
          <tr v-if="props.row.meta">
            <td>Meta:</td>
            <td>
              <vue-json-pretty :deep="3" :data="props.row.meta"></vue-json-pretty>
            </td>
          </tr>
        </b-table-simple>
      </template>

      <template slot="bottom-left">
        <b-button v-on:click="fetch" type="is-info" icon-left="refresh" outlined style="margin-left: 100px"
          >Refresh data</b-button
        >
      </template>
    </b-table>
  </div>
</template>

<script>
import { isEqual, pickBy, assign } from 'lodash';

export default {
  name: 'inventory',
  data() {
    return {
      items: [],
      total: 0,
      page: 1,
      perPage: 20,
      sortField: 'created_at',
      sortOrder: 'desc',
      defaultSortOrder: 'desc',
      loading: false,
      offset: 0,
      params: {},
      statusOprions: ['', 'Unknown', 'Pending', 'Pass', 'Error'],
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    async fetch() {
      this.loading = true;
      this.offset = (this.page - 1) * this.perPage;

      const params = assign(
        {
          sort_field: this.sortField,
          sort_order: this.sortOrder,
          limit: this.perPage,
          offset: this.offset,
        },
        this.params
      );

      const res = await this.$http.get('api/v1/inventory', { params });
      this.items = res.data;
      this.total = parseInt(res.headers['x-total'], 10);
      this.loading = false;
    },
    onPageChange(page) {
      this.page = page;
      this.fetch();
    },
    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
      this.fetch();
    },
    filtersChange(filters) {
      const params = pickBy(filters, (v) => v.length > 2);
      if (!isEqual(params, this.params)) {
        this.params = params;
        this.fetch();
      }
    },
  },
};
</script>
